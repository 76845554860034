import React from 'react'
import { Box, HeaderGemuniWrapper } from './styled'

const HeaderGem = () => {
  const gemuniMenu = [
    {
      link: `${process.env.REACT_APP_GEMUNI_URL}/swap` || '',
      text: 'trade',
      id: 'trade-page-link',
      subMenu: [
        {
          link: `${process.env.REACT_APP_GEMUNI_URL}/swap` || '',
          text: 'swap',
          id: 'swap-page-link',
        },
        {
          link: `${process.env.REACT_APP_GEMUNI_URL}/limit-orders` || '',
          text: 'limit',
          id: 'limit-page-link',
        },
        {
          link: `${process.env.REACT_APP_GEMUNI_URL}/liquidity` || '',
          text: 'liquidity',
          id: 'liquidity-page-link',
        },
      ],
    },
    {
      link: `${process.env.REACT_APP_GEMUNI_URL}/lottery` || '',
      text: 'lottery',
      id: 'lottery-page-link',
    },
    {
      link: '',
      text: 'info',
      id: 'info-page-link',
      check: true,
    },
  ]
  return (
    <HeaderGemuniWrapper className="header">
      <Box className="flex gap-20 items-center">
        <a href="http://14.161.39.254:47340/" style={{marginTop: '4px'}}>
          <img src={require('../../assets/logo.webp')} alt="QuickLogo" height={50} />
        </a>
        <Box className="gemuni-menu">
          {gemuniMenu.map((val) => (
            <Box className="gemuni-menu__wrapper" key={val.id}>
              <div className="gem-menu-link__wrapper">
                <a href={val.link} target={val.id === 'info-page-link' ? '' : "_blank"} rel="noopener noreferrer">
                  <small
                    className="text-capitalize"
                    style={{
                      color: val.check ? 'rgb(154, 106, 255)' : 'rgb(184, 173, 210)',
                    }}
                  >
                    {val.text}
                  </small>
                </a>
              </div>
              {val.subMenu && (
                <ul className="sub-menu">
                  {val?.subMenu?.map((item) => (
                    <a key={item.id} href={item.link} target="_blank" rel="noopener noreferrer">
                      <li>{item.text}</li>
                    </a>
                  ))}
                </ul>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </HeaderGemuniWrapper>
  )
}

export default HeaderGem
