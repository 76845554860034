import React, { useEffect } from 'react'
import 'feather-icons'

import TopTokenList from '../components/TokenList'
import { Heading } from '../Theme'
import Panel from '../components/Panel'
import { useAllTokenData } from '../contexts/TokenData'
import { PageWrapper, FullWrapper } from '../components'
import { RowBetween } from '../components/Row'
import { useMedia } from 'react-use'
import styled from 'styled-components'
import PoolsOverview from '../components/TokenList/TokenPage'


const Container = styled(FullWrapper)`
  grid-gap: 0px;
`

function AllTokensPage() {
  const allTokens = useAllTokenData()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const below600 = useMedia('(max-width: 800px)')

  return (
    <PageWrapper>
      <Container>
        <PoolsOverview tokens={allTokens} itemMax={50}/>
        <RowBetween>
          <Heading>All Cryptos</Heading>
        </RowBetween>
        <Panel style={{ padding: below600 && '1rem 0 0 0 ' }}>
          <TopTokenList tokens={allTokens} itemMax={50} />
        </Panel>
      </Container>
    </PageWrapper>
  )
}

export default AllTokensPage
