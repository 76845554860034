import React from 'react'
import styled from 'styled-components'
import Flex from '../../Box/Flex'
import Link from '../../Link'
import { socials, secondSocials, thirdSocials } from '../config'
// import Link from 'components/Link'

const StyledLink = styled(Link)`
  position: relative;
  height: 40px;
  &::before {
    position: absolute;
    inset: 0;
    content: '';
    border: 1px inset transparent;
    border-radius: 50%;
    transition: border 200ms;
  }
  &:hover::before {
    border: 1px solid #ffff;
  }
`

const SocialLinks = ({ ...props }) => (
  <Flex {...props}>
    {socials.map((social, index) => {
      const iconProps = {
        width: '40px',
        style: { cursor: 'pointer' },
      }
      const Icon = social.icon
      const mr = index < socials.length - 1 ? '24px' : 0
      return (
        <StyledLink external key={social.label} href={social.href} aria-label={social.label} mr={mr}>
          <Icon {...iconProps} />
        </StyledLink>
      )
    })}
  </Flex>
)

export default React.memo(SocialLinks, () => true)

export const SocialSecondLinks = ({ ...props }) => (
  <Flex {...props}>
    {secondSocials.map((social, index) => {
      const iconProps = {
        width: '40px',
        // color: darkColors.textSubtle,
        style: { cursor: 'pointer' },
      }
      const Icon = social.icon
      const mr = index < socials.length - 1 ? '24px' : 0
      return (
        <StyledLink external key={social.label} href={social.href} aria-label={social.label} mr={mr}>
          <Icon {...iconProps} />
        </StyledLink>
      )
    })}
  </Flex>
)

export const SocialThirdLinks = ({ ...props }) => (
  <Flex {...props}>
    {thirdSocials.map((social, index) => {
      const iconProps = {
        width: '40px',
        // color: darkColors.textSubtle,
        style: { cursor: 'pointer' },
      }
      const Icon = social.icon
      const mr = index < socials.length - 1 ? '24px' : 0
      return (
        <Flex key={index}>
          <StyledLink external key={social.label} href={social.href} aria-label={social.label}>
            <Icon {...iconProps} />
          </StyledLink>
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              color: '#FFFFFF',
              fontSize: '16px',
              fontWeight: '400',
              fontFamily: 'Inter',
              marginLeft: '0.7rem',
            }}
          >
            {social.text}
          </span>
        </Flex>
      )
    })}
  </Flex>
)
