import styled from 'styled-components'

export const Box = styled.div`
  `
export const HeaderGemuniWrapper = styled(Box)`
  background-color: rgba(43, 27, 78, 0.4);
  border-bottom: 1px solid rgb(56, 50, 65);
  margin-bottom: 36px;
  padding: 0 1.5rem;
  .gemuni-menu {
    display: flex;
  }

  .gemuni-menu__wrapper {
    position: relative;
    a {
      text-decoration: none;
      small {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        color: rgb(184, 173, 210);
        font-weight: 700;
        padding: 0px 16px;
        height: 48px;
        font-size: 14px;
      }
    }
    &:hover {
      background: rgba(43, 27, 78, 0.4);
      border-radius: 16px;
      small {
        color: rgb(154, 106, 255);
        font-weight: 600;
      }
    }
    .sub-menu {
      position: absolute;
      background-color: rgba(43, 27, 78, 0.8);
      border: 1px solid rgb(56, 50, 65, 1);
      border-radius: 16px;
      display: none;
      padding: 0;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        border-width: 20px 26px;
        border-style: solid;
        top: -26px;
        border-color: transparent;
        cursor: pointer;
      }
      li {
        text-decoration: none;
        list-style: none;
        display: flex;
        align-items: center;
        color: rgb(184, 173, 210);
        padding: 16px;
        width: 200px;
        text-transform: capitalize;
        cursor: pointer;
        font-weight: 600;
        &:hover {
          background-color: rgba(43, 27, 78, 0.4);
          color: rgb(154, 106, 255);
          font-weight: 600;
        }
      }
      a {
        &:first-child {
          li {
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
          }
        }
        &:last-child {
          li {
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
          }
        }
      }
    }
  }
  .gemuni-menu__wrapper:hover .sub-menu {
    display: block;
    z-index: 100;
  }
  .flex {
    display: flex;
  }
  .gap-20 {
    gap: 20px;
  }
  .items-center {
    align-items: center;
  }
  .text-capitalize {
    text-transform: capitalize;
  }
`
