import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { useSavedTokens, useSavedPairs } from '../../contexts/LocalStorage'
import TokenLogo from '../TokenLogo'
import AccountSearch from '../AccountSearch'
import { Bookmark, ChevronRight, X } from 'react-feather'
import { ButtonFaded } from '../ButtonStyled'
import FormattedName from '../FormattedName'
import { useMedia } from 'react-use'
import { Box, Flex, Text } from 'rebass'
import { CustomLink } from '../Link'
import { Divider } from '../../components'
import { formattedNum, formattedPercent } from '../../utils'
import DoubleTokenLogo from '../DoubleLogo'
import { TYPE } from '../../Theme'
import { SWAP_FEE_TO_LP } from '../../constants'
import LocalLoader from '../LocalLoader'
import { useAllPairData } from '../../contexts/PairData'

const BoxCard = styled.div`
  div {
    border-radius: 12px;
    min-height: 145px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const BoxList = styled.div`
  width: 100%;
  height: 100%;
  overflow: inherit;
  background: rgba(43, 27, 78, 0.4);
  border-radius: 24px;
  border: 1px solid rgba(43, 27, 78, 0.4);
`
const PageButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 0.5em;
`
const HeadingText = styled.h1`
  font-weight: 900;
  font-size: 25px;
  line-height: 27px;
  color: #ffffff;
  text-shadow: 0px 4px 20px #8668df;
  margin-top: 40px;
  margin-bottom: 33px;
`

const Arrow = styled.div`
  color: ${({ theme }) => theme.primary1};
  opacity: ${(props) => (props.faded ? 0.3 : 1)};
  padding: 0 20px;
  user-select: none;
  :hover {
    cursor: pointer;
  }
`

const List = styled(Box)`
  -webkit-overflow-scrolling: touch;
`

const DashGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 20px 3fr repeat(4, 1fr);
  padding: 0 32px;

  > * {
    justify-content: flex-end;

    &:first-child {
      justify-content: flex-start;
      text-align: left;
      width: 100px;
    }
  }

  > :nth-child(2) {
    justify-content: flex-start;
  }
  @media screen and (max-width: 900px) {
    grid-template-columns: 20px 2fr repeat(3, 1fr);
    & :nth-child(4) {
      display: none;
    }
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 20px 2fr repeat(2, 1fr);
    & :nth-child(5) {
      display: none;
    }
  }

  @media screen and (max-width: 670px) {
    grid-template-columns: 1fr 1fr;
    padding-left: 0;
    > *:first-child {
      display: none;
    }
  }

  @media screen and (min-width: 740px) {
    padding: 0 1.125rem;
    grid-template-columns: 1.5fr 1fr 1fr;
    grid-template-areas: ' name liq vol pool ';
  }

  @media screen and (min-width: 1080px) {
    padding: 0 1.125rem;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: ' name liq vol volWeek fees apy';
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns: 1.5fr 1fr 1fr 1.5fr 1fr 1fr;
    grid-template-areas: ' name liq vol volWeek fees apy';
  }
`
const DashGridRow = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 20px 3fr repeat(4, 1fr);
  padding: 0 32px;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }

  > * {
    justify-content: flex-end;

    &:first-child {
      justify-content: flex-start;
      text-align: left;
      width: 100px;
    }
  }

  > :nth-child(2) {
    justify-content: flex-start;
  }
  @media screen and (max-width: 900px) {
    grid-template-columns: 20px 2fr repeat(3, 1fr);
    & :nth-child(4) {
      display: none;
    }
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 20px 2fr repeat(2, 1fr);
    & :nth-child(5) {
      display: none;
    }
  }

  @media screen and (max-width: 670px) {
    grid-template-columns: 1fr 1fr;
    padding-left: 0;
    > *:first-child {
      display: none;
    }
  }

  @media screen and (min-width: 740px) {
    padding: 0 1.125rem;
    grid-template-columns: 1.5fr 1fr 1fr;
    grid-template-areas: ' name liq vol pool ';
  }

  @media screen and (min-width: 1080px) {
    padding: 0 1.125rem;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: ' name liq vol volWeek fees apy';
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns: 1.5fr 1fr 0.5fr 1fr 1fr 1fr;
    grid-template-areas: ' name liq vol volWeek fees apy';
  }
`

const ListWrapper = styled.div``

const BoxIconSort = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 10px;
`
const ClickableText = styled(Text)`
  font-family: 'inter';
  font-style: regular;
  font-size: 16px;
  line-height: 24px;
  line-height: 124%;
  color: #777e90;
  display: flex;
  flex-direction: row;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  text-align: end;
  user-select: none;
`

const ClickableSpan = styled(Text)`
  font-family: 'inter';
  font-style: regular;
  font-size: 16px;
  line-height: 24px;
  line-height: 124%;
  color: #777e90;
`

const DataText = styled(Flex)`
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.text1};

  & > * {
    font-size: 14px;
  }

  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
`

const SORT_FIELD = {
  LIQ: 0,
  VOL: 1,
  VOL_7DAYS: 3,
  FEES: 4,
  APY: 5,
}

const FIELD_TO_VALUE = {
  [SORT_FIELD.LIQ]: 'trackedReserveUSD', // sort with tracked volume only
  [SORT_FIELD.VOL]: 'oneDayVolumeUSD',
  [SORT_FIELD.VOL_7DAYS]: 'oneWeekVolumeUSD',
  [SORT_FIELD.FEES]: 'oneDayVolumeUSD',
}

function PoolsOverview({ disableLinks, maxItems = 10, history }) {
  const below600 = useMedia('(max-width: 600px)')
  const below740 = useMedia('(max-width: 740px)')
  const below1080 = useMedia('(max-width: 1080px)')

  // pagination
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const ITEMS_PER_PAGE = maxItems

  // sorting
  const [sortDirection, setSortDirection] = useState(true)
  const [sortedColumn, setSortedColumn] = useState(SORT_FIELD.volumeUSD)

  const [savedPairs, removePair] = useSavedPairs()
  const allPairs = useAllPairData()
  const favoriteAddress = useMemo(() => {
    return Object.keys(allPairs).length > 0
      ? Object.keys(allPairs)?.filter((token) => Object.keys(savedPairs).indexOf(token) > -1)
      : []
  }, [allPairs, savedPairs])

  const pairs = useMemo(() => {
    return favoriteAddress.length && Object.keys(allPairs).length > 0
      ? favoriteAddress.map((address) => allPairs[address])
      : []
  }, [favoriteAddress, allPairs])

  const ListItem = ({ pairAddress, index }) => {
    console.log('pairAddress', pairAddress)
    const [savedPairs, removePair] = useSavedPairs()
    const allPairs = useAllPairData()
    const favoriteAddress = useMemo(() => {
      return Object.keys(allPairs).length > 0
        ? Object.keys(allPairs)?.filter((token) => Object.keys(savedPairs).indexOf(token) > -1)
        : []
    }, [allPairs, savedPairs])
    const pairData = pairs[pairAddress]

    console.log(favoriteAddress, 'favoriteAddresss')

    if (pairData && pairData.token0 && pairData.token1) {
      const liquidity = formattedNum(pairData.trackedReserveUSD, true)
      const dailyVolume = formattedNum(pairData.oneDayVolumeUSD, true)
      const weeklyVolume = formattedNum(pairData.oneWeekVolumeUSD, true)
      const weeklyFees = formattedNum(pairData.oneWeekVolumeUSD * SWAP_FEE_TO_LP, true)
      const apy = formattedPercent(
        (pairData.oneWeekVolumeUSD * SWAP_FEE_TO_LP * (365 / 7) * 100) / pairData.trackedReserveUSD
      )

      return (
        <DashGridRow style={{ height: '48px' }} disableLinks={disableLinks} focus={true}>
          <DataText area="name" fontWeight="500">
            {!below600 && <div style={{ marginRight: '40px', width: '10px' }}>{index}</div>}
            <DoubleTokenLogo
              size={below600 ? 16 : 24}
              a0={pairData.token0.id}
              a1={pairData.token1.id}
              margin={!below740}
            />
            <CustomLink
              style={{ marginLeft: '20px', whiteSpace: 'nowrap' }}
              to={'/pair/' + pairData.id}
              color="#ffffff"
            >
              <Text ml="10px" style={{ whiteSpace: 'nowrap', display: 'flex', flexDrection: 'row' }}>
                <Text>{pairData.token0.symbol}/</Text>
                <Text color="rgb(128, 126, 159)" maxCharacters={below600 ? 8 : 16} adjustSize={true} link={true}>
                  {pairData.token1.symbol}
                </Text>
              </Text>
            </CustomLink>
          </DataText>
          <DataText area="liq">{liquidity}</DataText>
          <DataText area="vol">{dailyVolume}</DataText>
          {!below1080 && <DataText area="volWeek">{weeklyVolume}</DataText>}
          {!below1080 && <DataText area="fees">{weeklyFees}</DataText>}
          {!below1080 && <DataText area="apy">{apy}</DataText>}
        </DashGridRow>
      )
    } else {
      return ''
    }
  }

  const pairList =
    pairs &&
    Object.keys(pairs)
      .sort((addressA, addressB) => {
        const pairA = pairs[addressA]
        const pairB = pairs[addressB]
        if (sortedColumn === SORT_FIELD.APY) {
          const apy0 = (pairA.oneWeekVolumeUSD * SWAP_FEE_TO_LP * (365 / 7) * 100) / parseFloat(pairA.trackedReserveUSD)
          const apy1 = (pairB.oneWeekVolumeUSD * SWAP_FEE_TO_LP * (365 / 7) * 100) / parseFloat(pairB.trackedReserveUSD)
          return apy0 > apy1 ? (sortDirection ? -1 : 1) : sortDirection ? 1 : -1
        }
        return parseFloat(pairA[FIELD_TO_VALUE[sortedColumn]]) > parseFloat(pairB[FIELD_TO_VALUE[sortedColumn]])
          ? sortDirection
            ? -1
            : 1
          : sortDirection
          ? 1
          : -1
      })
      .slice(ITEMS_PER_PAGE * (page - 1), page * ITEMS_PER_PAGE)
      .map((pairAddress, index) => {
        return (
          pairAddress && (
            <div key={index}>
              <ListItem key={index} index={(page - 1) * ITEMS_PER_PAGE + index + 1} pairAddress={pairAddress} />
              <Divider />
            </div>
          )
        )
      })
  const handleSort = useCallback(
    (newField) => {
      setSortedColumn(newField)
      setSortDirection(sortedColumn !== newField ? true : !sortDirection)
    },
    [sortDirection, sortedColumn]
  )

  const IconSort = (dow) => {
    return (
      <>
        {dow ? (
          <BoxIconSort>
            <img src="/icons/up.svg" width="10px" alt="icon sort" height="10px" />
            <img src="/icons/down_blur.svg" width="10px" alt="icon sort" height="10px" style={{ opacity: '0.3' }} />
          </BoxIconSort>
        ) : (
          <BoxIconSort>
            <img src="/icons/up_blur.svg" alt="icon sort" width="10px" height="10px" style={{ opacity: '0.3' }} />
            <img src="/icons/down.svg" alt="icon sort" width="10px" height="10px" />
          </BoxIconSort>
        )}
      </>
    )
  }

  const arrow = useCallback(
    (field) => {
      const directionArrow = !sortDirection ? IconSort(true) : IconSort(false)
      return sortedColumn === field ? directionArrow : ''
    },
    [sortDirection, sortedColumn]
  )

  return (
    <ListWrapper>
      <HeadingText>Your Watchlist</HeadingText>
      {favoriteAddress.length > 0 ? (
        <>
          <DashGrid
            center={true}
            disableLinks={disableLinks}
            style={{ height: 'fit-content', padding: '0 1.125rem 1rem 1.125rem' }}
          >
            <Flex alignItems="center" justifyContent="flexStart">
              <ClickableSpan> # </ClickableSpan>
              <ClickableSpan paddingLeft="125px">POOL</ClickableSpan>
            </Flex>
            <Flex alignItems="center">
              <ClickableText
                area="vol"
                onClick={() => {
                  handleSort(SORT_FIELD.volumeUSD)
                }}
              >
                VOLUME 24H {arrow(SORT_FIELD.volumeUSD)}
              </ClickableText>
            </Flex>
            {!below1080 && (
              <Flex alignItems="center" justifyContent="flexEnd">
                <ClickableText
                  area="volWeek"
                  onClick={() => {
                    handleSort(SORT_FIELD.VOL_7DAYS)
                  }}
                >
                  VOLUME 7D {arrow(SORT_FIELD.VOL_7DAYS)}
                </ClickableText>
              </Flex>
            )}
            {!below1080 && (
              <Flex alignItems="center" justifyContent="flexEnd">
                <ClickableText
                  area="fees"
                  onClick={() => {
                    handleSort(SORT_FIELD.FEES)
                  }}
                >
                  LP REWARD FEES 24h {arrow(SORT_FIELD.FEES)}
                </ClickableText>
              </Flex>
            )}
            {!below1080 && (
              <Flex alignItems="center" justifyContent="flexEnd">
                <ClickableText
                  area="apy"
                  onClick={() => {
                    handleSort(SORT_FIELD.APY)
                  }}
                >
                  LP REWARD APR {arrow(SORT_FIELD.APY)}
                </ClickableText>
              </Flex>
            )}
            <Flex alignItems="center" justifyContent="flexEnd">
              <ClickableText
                area="liq"
                onClick={() => {
                  handleSort(SORT_FIELD.LIQ)
                }}
              >
                LIQUIDITY{arrow(SORT_FIELD.LIQ)}
              </ClickableText>
            </Flex>
          </DashGrid>
          <Divider />
          <List p={0}>{pairList && pairList.length > 0 ? pairList : <LocalLoader />}</List>
          <PageButtons>
            <div
              onClick={(e) => {
                setPage(page === 1 ? page : page - 1)
              }}
            >
              <Arrow faded={page === 1 ? true : false}>←</Arrow>
            </div>
            <TYPE.body>{'Page ' + page + ' of ' + maxPage}</TYPE.body>
            <div
              onClick={(e) => {
                setPage(page === maxPage ? page : page + 1)
              }}
            >
              <Arrow faded={page === maxPage ? true : false}>→</Arrow>
            </div>
          </PageButtons>
        </>
      ) : (
        <BoxCard>
          <BoxList>
            <Text px="24px" color="grey" py="16px">
              Saved pools will appear here
            </Text>
          </BoxList>
        </BoxCard>
      )}
    </ListWrapper>
  )
}

export default PoolsOverview
