import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import PairList from '../components/PairList'
import { AutoRow, RowBetween } from '../components/Row'
import TopTokenList from '../components/TokenList'
import TxnList from '../components/TxnList'

import { CustomLink } from '../components/Link'
import Panel from '../components/Panel'
import { useGlobalTransactions } from '../contexts/GlobalData'
import { useAllPairData } from '../contexts/PairData'
import { useAllTokenData } from '../contexts/TokenData'
import { Heading, ThemedBackground, TYPE } from '../Theme'

import { ContentWrapper, PageWrapper } from '../components'
import CheckBox from '../components/Checkbox'
import QuestionHelper from '../components/QuestionHelper'

const ListOptions = styled(AutoRow)`
  height: 40px;
  width: 100%;
  font-size: 1.25rem;
  font-weight: 600;

  @media screen and (max-width: 640px) {
    font-size: 1rem;
  }
`

function GlobalPage() {
  // get data for lists and totals
  const allPairs = useAllPairData()
  const allTokens = useAllTokenData()
  const transactions = useGlobalTransactions()

  // scrolling refs
  useEffect(() => {
    document.querySelector('body').scrollTo({
      behavior: 'smooth',
      top: 0,
    })
  }, [])

  // for tracked data on pairs
  const [useTracked, setUseTracked] = useState(true)

  return (
    <PageWrapper>
      {/* <ThemedBackground backgroundColor={transparentize(0.6, '#ff007a')} /> */}
      <ContentWrapper>
        <ListOptions gap="10px" style={{ marginTop: '2rem', marginBottom: '.6rem' }}>
          <Heading fontSize={'1.125rem'} style={{ whiteSpace: 'nowrap' }}>
            Top Tokens
          </Heading>
        </ListOptions>
        <Panel>
          <TopTokenList tokens={allTokens} />
        </Panel>
        <ListOptions gap="10px" style={{ marginTop: '2rem', marginBottom: '.5rem' }}>
          <Heading fontSize={'1rem'} style={{ whiteSpace: 'nowrap' }}>
            Top Pairs
          </Heading>
        </ListOptions>
        <Panel>
          <PairList pairs={allPairs} useTracked={useTracked} />
        </Panel>
      </ContentWrapper>
    </PageWrapper>
  )
}

export default withRouter(GlobalPage)
