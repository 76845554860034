import React, { useCallback, useEffect, useState } from 'react'
import { ApolloProvider } from 'react-apollo'
import { BrowserRouter, Redirect, Route, Switch, NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { client } from './apollo/client'
import { useGlobalChartData, useGlobalData } from './contexts/GlobalData'
import AccountPage from './pages/AccountPage'
import AllPairsPage from './pages/AllPairsPage'
import AllTokensPage from './pages/AllTokensPage'
import GlobalPage from './pages/GlobalPage'
import PairPage from './pages/PairPage'
import TokenPage from './pages/TokenPage'
import { isAddress } from './utils'

import { ContentWrapper as ContainerButton } from './components'
import GoogleAnalyticsReporter from './components/analytics/GoogleAnalyticsReporter'
import LocalLoader from './components/LocalLoader'
import { PAIR_BLACKLIST, TOKEN_BLACKLIST } from './constants'
import { useLatestBlocks } from './contexts/Application'
import AccountLookup from './pages/AccountLookup'
import HeaderGem from './components/HeaderGemuni/HeaderGem'
import { useHistory, useLocation } from 'react-router-dom'
import Footer from './components/Footer'

const AppWrapper = styled.div`
  position: relative;
  width: 100%;
`
const ContentWrapper = styled.div`
  background-image: url('/images/background_info.webp');
  background-size: contain;
  background-repeat: round;
  @media screen and (max-width: 1400px) {
    grid-template-columns: 220px 1fr;
  }

  @media screen and (max-width: 1080px) {
    grid-template-columns: 1fr;
    max-width: 100vw;
    overflow: hidden;
    grid-gap: 0;
  }
`

const Right = styled.div`
  position: fixed;
  right: 0;
  bottom: 0rem;
  z-index: 99;
  width: ${({ open }) => (open ? '220px' : '64px')};
  height: ${({ open }) => (open ? 'fit-content' : '64px')};
  overflow: auto;
  background-color: ${({ theme }) => theme.bg1};
  @media screen and (max-width: 1400px) {
    display: none;
  }
`

const Center = styled.div`
  margin: 0 100px;
  height: 100%;
  z-index: 9999;
  transition: width 0.25s ease;
  /* background-color: ${({ theme }) => theme.onlyLight}; */
`

const WarningWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const WarningBanner = styled.div`
  background-color: #ff6871;
  padding: 1.5rem;
  color: white;
  width: 100%;
  text-align: center;
  font-weight: 500;
`

const ButtonWrapper = styled.div`
  display: inline-flex;
  height: 46px;
  border-radius: 8px;
  width: max-content;
  margin-bottom: 58px;

  background-color: rgb(59, 60, 78);
`

const TabButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-inline: 24px;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.3);

  &.active {
    color: white;
    background: linear-gradient(90deg, #06d3e6 -0.15%, #ffdb0d 96.99%);
    &:hover {
      opacity: 1 !important;
    }
  }
  &:hover {
    opacity: 0.5;
  }
`

/**
 * Wrap the component with the header and sidebar pinned tab
 */
const LayoutWrapper = ({ children, savedOpen, setSavedOpen }) => {
  const location = useLocation()
  const history = useHistory()
  const [tabActive, setTabActive] = useState('overview')

  useEffect(() => {
    switch (true) {
      case location.pathname.includes('token'):
        setTabActive('token')
        break
      case location.pathname.includes('pair'):
        setTabActive('pair')
        break
      default:
        setTabActive('overview')
        break
    }
  }, [location])

  return (
    <>
      <ContentWrapper open={savedOpen}>
        {/* <SideNav /> */}
        <HeaderGem />
        <Center id="center">
          <ContainerButton>
            <ButtonWrapper>
              <TabButton className={tabActive === 'overview' ? 'active' : ''} onClick={() => history.push('/home')}>
                Overview
              </TabButton>
              <TabButton className={tabActive === 'token' ? 'active' : ''} onClick={() => history.push('/tokens')}>
                Tokens
              </TabButton>
              <TabButton className={tabActive === 'pair' ? 'active' : ''} onClick={() => history.push('/pairs')}>
                Pairs
              </TabButton>
            </ButtonWrapper>
          </ContainerButton>
          {children}
        </Center>
        <Footer />
      </ContentWrapper>
    </>
  )
}

const BLOCK_DIFFERENCE_THRESHOLD = 30

function App() {
  const [savedOpen, setSavedOpen] = useState(false)

  const globalData = useGlobalData()
  const globalChartData = useGlobalChartData()
  const [latestBlock, headBlock] = useLatestBlocks()

  // show warning
  const showWarning = headBlock && latestBlock ? headBlock - latestBlock > BLOCK_DIFFERENCE_THRESHOLD : false

  return (
    <ApolloProvider client={client}>
      <AppWrapper>
        {/* {showWarning && (
          <WarningWrapper>
            <WarningBanner>
              {`Warning: The data on this site has only synced to Ethereum block ${latestBlock} (out of ${headBlock}). Please check back soon.`}
            </WarningBanner>
          </WarningWrapper>
        )} */}
        {globalData &&
        Object.keys(globalData).length > 0 &&
        globalChartData &&
        Object.keys(globalChartData).length > 0 ? (
          <BrowserRouter>
            <Route component={GoogleAnalyticsReporter} />
            <Switch>
              <Route
                exacts
                strict
                path="/token/:tokenAddress"
                render={({ match }) => {
                  if (
                    isAddress(match.params.tokenAddress.toLowerCase()) &&
                    !Object.keys(TOKEN_BLACKLIST).includes(match.params.tokenAddress.toLowerCase())
                  ) {
                    return (
                      <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                        <TokenPage address={match.params.tokenAddress.toLowerCase()} />
                      </LayoutWrapper>
                    )
                  } else {
                    return <Redirect to="/home" />
                  }
                }}
              />
              <Route
                exacts
                strict
                path="/pair/:pairAddress"
                render={({ match }) => {
                  if (
                    isAddress(match.params.pairAddress.toLowerCase()) &&
                    !Object.keys(PAIR_BLACKLIST).includes(match.params.pairAddress.toLowerCase())
                  ) {
                    return (
                      <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                        <PairPage pairAddress={match.params.pairAddress.toLowerCase()} />
                      </LayoutWrapper>
                    )
                  } else {
                    return <Redirect to="/home" />
                  }
                }}
              />
              <Route
                exacts
                strict
                path="/account/:accountAddress"
                render={({ match }) => {
                  if (isAddress(match.params.accountAddress.toLowerCase())) {
                    return (
                      <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                        <AccountPage account={match.params.accountAddress.toLowerCase()} />
                      </LayoutWrapper>
                    )
                  } else {
                    return <Redirect to="/home" />
                  }
                }}
              />

              <Route path="/home">
                <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                  <GlobalPage />
                </LayoutWrapper>
              </Route>

              <Route path="/tokens">
                <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                  <AllTokensPage />
                </LayoutWrapper>
              </Route>

              <Route path="/pairs">
                <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                  <AllPairsPage />
                </LayoutWrapper>
              </Route>

              <Route path="/accounts">
                <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                  <AccountLookup />
                </LayoutWrapper>
              </Route>

              <Redirect to="/home" />
            </Switch>
          </BrowserRouter>
        ) : (
          <LocalLoader fill="true" />
        )}
      </AppWrapper>
    </ApolloProvider>
  )
}

export default App
