import React, { useState, useEffect } from 'react'
import 'feather-icons'
import { transparentize } from 'polished'
import { withRouter } from 'react-router-dom'
import { useMedia } from 'react-use'
import { Text } from 'rebass'
import styled from 'styled-components'
import { BlockedMessageWrapper, BlockedWrapper, ContentWrapper, PageWrapper } from '../components'
import { AutoColumn } from '../components/Column'
import FormattedName from '../components/FormattedName'
import Link, { BasicLink } from '../components/Link'
import Loader from '../components/LocalLoader'
import PairList from '../components/PairList'
import Panel from '../components/Panel'
import { AutoRow, RowBetween, RowFixed } from '../components/Row'
import TokenChart from '../components/TokenChart'
import TokenLogo from '../components/TokenLogo'
import TxnList from '../components/TxnList'
import Warning from '../components/Warning'
import { BLOCKED_WARNINGS, TOKEN_BLACKLIST, TXN_TYPE } from '../constants'
import { useListedTokens } from '../contexts/Application'
import { usePathDismissed, useSavedTokens } from '../contexts/LocalStorage'
import { useDataForList } from '../contexts/PairData'
import { useTokenData, useTokenPairs, useTokenTransactions } from '../contexts/TokenData'
import { formatCurrencyToString, formatNumberToString } from '../formatNumber'
import { useColor } from '../hooks'
import {
  ButtonAdd,
  ButtonLinear,
  FlexBox,
  FlexItemCenter,
  Icon,
  IconsBreadcrumb,
  LabelStyled,
  RadioStyled,
  TextCurrency,
  TextInternal,
  TextPurple,
  ThemedBackground,
  TYPE,
} from '../Theme'
import { formattedNum, formattedPercent, getPoolLink, getSwapLink, shortenAddress } from '../utils'

const DashboardWrapper = styled.div`
  width: 100%;
`

const PanelWrapper = styled.div`
  /* grid-template-columns: repeat(3, 1fr);
  grid-template-rows: max-content; */
  gap: 6px;
  display: grid;
  width: 24%;
  align-items: start;
  @media screen and (max-width: 1024px) {
    width: 100%;
    grid-template-columns: 1fr;
    align-items: stretch;
    > * {
      grid-column: 1 / 4;
    }

    > * {
      &:first-child {
        width: 100%;
      }
    }
  }
`

// const TokenDetailsLayout = styled.div`
//   display: inline-grid;
//   width: 100%;
//   grid-template-columns: auto auto auto 1fr;
//   column-gap: 30px;
//   align-items: start;

//   &:last-child {
//     align-items: center;
//     justify-items: end;
//   }
//   @media screen and (max-width: 1024px) {
//     grid-template-columns: 1fr;
//     align-items: stretch;
//     > * {
//       /* grid-column: 1 / 4; */
//       margin-bottom: 1rem;
//     }

//     &:last-child {
//       align-items: start;
//       justify-items: start;
//     }
//   }
// `

// const WarningIcon = styled(AlertCircle)`
//   stroke: ${({ theme }) => theme.text1};
//   height: 16px;
//   width: 16px;
//   opacity: 0.6;
// `

const SpanSymbol = styled.span`
  color: rgb(184, 173, 210);
  font-weight: 400;
  margin-left: 12px;
  line-height: 1;
  font-size: 20px;
`

const WarningGrouping = styled.div`
  opacity: ${({ disabled }) => disabled && '0.4'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
`

function TokenPage({ address, history }) {
  const {
    id,
    name,
    symbol,
    priceUSD,
    oneDayVolumeUSD,
    totalLiquidityUSD,
    volumeChangeUSD,
    oneDayVolumeUT,
    volumeChangeUT,
    priceChangeUSD,
    liquidityChangeUSD,
    oneDayTxns,
    txnChange,
  } = useTokenData(address)
  const [txFilter, setTxFilter] = useState(TXN_TYPE.ALL)

  useEffect(() => {
    document.querySelector('body').scrollTo(0, 0)
  }, [])

  // detect color from token
  const backgroundColor = useColor(id, symbol)

  const allPairs = useTokenPairs(address)

  // pairs to show in pair list
  const fetchedPairsList = useDataForList(allPairs)

  // all transactions with this token
  const transactions = useTokenTransactions(address)

  // price
  const price = priceUSD ? formattedNum(priceUSD, true) : ''
  const priceChange = priceChangeUSD ? formattedPercent(priceChangeUSD) : ''

  // volume
  // const volume = formattedNum(!!oneDayVolumeUSD ? oneDayVolumeUSD : oneDayVolumeUT, true)

  const usingUtVolume = oneDayVolumeUSD === 0 && !!oneDayVolumeUT
  const volumeChange = formattedPercent(!usingUtVolume ? volumeChangeUSD : volumeChangeUT)

  // liquidity
  // const liquidity = formattedNum(totalLiquidityUSD, true)
  const liquidityChange = formattedPercent(liquidityChangeUSD)

  // transactions
  const txnChangeFormatted = formattedPercent(txnChange)

  const below1080 = useMedia('(max-width: 1080px)')
  // const below800 = useMedia('(max-width: 800px)')
  const below600 = useMedia('(max-width: 600px)')
  const below500 = useMedia('(max-width: 500px)')

  // format for long symbol
  const LENGTH = below1080 ? 10 : 16
  const formattedSymbol = symbol?.length > LENGTH ? symbol.slice(0, LENGTH) + '...' : symbol

  const [dismissed, markAsDismissed] = usePathDismissed(history.location.pathname)
  const [savedTokens, addToken, removeToken] = useSavedTokens()
  const listedTokens = useListedTokens()

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    })
  }, [])

  // const [useTracked, setUseTracked] = useState(true)

  if (TOKEN_BLACKLIST.includes(address)) {
    return (
      <BlockedWrapper>
        <BlockedMessageWrapper>
          <AutoColumn gap="1rem" justify="center">
            <TYPE.light style={{ textAlign: 'center' }}>
              {BLOCKED_WARNINGS[address] ?? `This token is not supported.`}
            </TYPE.light>
            <Link external={true} href={'https://etherscan.io/address/' + address}>{`More about ${shortenAddress(
              address
            )}`}</Link>
          </AutoColumn>
        </BlockedMessageWrapper>
      </BlockedWrapper>
    )
  }
  const RadioGroup = () => {
    return (
      <RowFixed area="txn" width="100% !important" gap="10px" pl={4} margin="24px 0">
        <LabelStyled
          onClick={() => {
            setTxFilter(TXN_TYPE.ALL)
          }}
        >
          <RadioStyled type="radio" checked={txFilter === TXN_TYPE.ALL} />
          All
        </LabelStyled>
        <LabelStyled
          onClick={() => {
            setTxFilter(TXN_TYPE.SWAP)
          }}
        >
          <RadioStyled type="radio" checked={txFilter === TXN_TYPE.SWAP} />
          Swaps
        </LabelStyled>
        <LabelStyled
          onClick={() => {
            setTxFilter(TXN_TYPE.ADD)
          }}
        >
          <RadioStyled type="radio" checked={txFilter === TXN_TYPE.ADD} />
          Adds
        </LabelStyled>
        <LabelStyled
          onClick={() => {
            setTxFilter(TXN_TYPE.REMOVE)
          }}
        >
          <RadioStyled type="radio" checked={txFilter === TXN_TYPE.REMOVE} />
          Removes
        </LabelStyled>
      </RowFixed>
    )
  }

  return (
    <PageWrapper>
      <ThemedBackground backgroundColor={transparentize(0.6, backgroundColor)} />
      <Warning
        type={'token'}
        show={!dismissed && listedTokens && !listedTokens.includes(address)}
        setShow={markAsDismissed}
        address={address}
      />
      <ContentWrapper>
        <RowBetween style={{ flexWrap: 'wrap', alingItems: 'start' }}>
          <AutoRow align="center" style={{ width: 'fit-content' }}>
            <TYPE.body style={{ display: 'flex', alignItems: 'center' }}>
              <BasicLink to="/tokens">{'Tokens '}</BasicLink>
              <FlexItemCenter>
                <IconsBreadcrumb src="/icons/inext.svg" />
                <span> {symbol} </span>
              </FlexItemCenter>
            </TYPE.body>
            <Link
              style={{ width: 'fit-content' }}
              color={backgroundColor}
              external
              href={'https://etherscan.io/address/' + address}
            >
              <Text style={{ marginLeft: '.15rem' }} fontSize={'14px'} fontWeight={400}>
                ({address.slice(0, 8) + '...' + address.slice(36, 42)})
              </Text>
            </Link>
          </AutoRow>
          <FlexItemCenter>
            <Link
              style={{ width: 'fit-content' }}
              color={backgroundColor}
              external
              href={'https://snowtrace.io/address/' + address}
            >
              <FlexItemCenter style={{ marginRight: '10px' }}>
                <TextInternal>View on explorer</TextInternal>
                <Icon src="/icons/iextend.svg" />
              </FlexItemCenter>
            </Link>
            {!!savedTokens[address] ? (
              <Icon src={'/icons/istar-yellow.svg'} onClick={() => removeToken(address)} />
            ) : (
              <Icon src={'/icons/istar.svg'} onClick={() => addToken(address, symbol)} />
            )}
          </FlexItemCenter>
          {/* {!below600 && <Search small={true} />} */}
        </RowBetween>
        <WarningGrouping disabled={!dismissed && listedTokens && !listedTokens.includes(address)}>
          <DashboardWrapper style={{ marginTop: below1080 ? '0' : '1rem' }}>
            <RowBetween
              style={{
                flexWrap: 'wrap',
                marginBottom: '0.5rem',
                alignItems: 'flex-start',
              }}
            >
              <RowFixed style={{ flexWrap: 'wrap' }}>
                <RowFixed style={{ alignItems: 'baseline' }}>
                  <TokenLogo address={address} size="32px" style={{ alignSelf: 'center' }} />
                  <TYPE.main fontSize={below1080 ? '1.5rem' : '2rem'} fontWeight={500} style={{ margin: '0 1rem' }}>
                    <RowFixed gap="6px">
                      <FormattedName
                        text={name ? name + ' ' : ''}
                        maxCharacters={16}
                        style={{ marginRight: '6px' }}
                        fontSize="40px"
                        fontWeight="600"
                      />
                      <SpanSymbol>{formattedSymbol ? `(${formattedSymbol})` : ''}</SpanSymbol>
                    </RowFixed>
                  </TYPE.main>{' '}
                  {/* {!below1080 && (
                    <>
                      <TYPE.main fontSize={'1.5rem'} fontWeight={500} style={{ marginRight: '1rem' }}>
                        {price}
                      </TYPE.main>
                      {priceChange}
                    </>
                  )} */}
                </RowFixed>
              </RowFixed>
              <span>
                <RowFixed ml={below500 ? '0' : '2.5rem'} mt={below500 ? '1rem' : '0'}>
                  {/* {!!!savedTokens[address] && !below800 ? (
                    <Hover onClick={() => addToken(address, symbol)}>
                      <StyledIcon>
                        <PlusCircle style={{ marginRight: '0.5rem' }} />
                      </StyledIcon>
                    </Hover>
                  ) : !below1080 ? (
                    <StyledIcon>
                      <Bookmark style={{ marginRight: '0.5rem', opacity: 0.4 }} />
                    </StyledIcon>
                  ) : (
                    <></>
                  )} */}
                  <Link href={process.env.REACT_APP_LIQUIDITY_URL} target="_blank">
                    <ButtonAdd>Add Liquidity</ButtonAdd>
                  </Link>
                  <Link href={process.env.REACT_APP_SWAP_URL} target="_blank">
                    <ButtonLinear>Trade</ButtonLinear>
                  </Link>
                </RowFixed>
              </span>
            </RowBetween>
            <RowFixed>
              <div style={{ width: '45px', height: '45px' }}></div>
              {!below1080 && (
                <>
                  <FormattedName
                    text={price}
                    maxCharacters={16}
                    style={{ marginRight: '1rem' }}
                    fontSize="24px"
                    fontWeight="600"
                  />
                  <FormattedName
                    text={priceChange}
                    maxCharacters={16}
                    style={{ marginRight: '1rem' }}
                    fontSize="14px"
                    fontWeight="600"
                  />
                </>
              )}
            </RowFixed>
            <FlexBox>
              <PanelWrapper
                style={{
                  marginTop: below1080 ? '0' : '1.5rem',
                  background: 'rgba(43, 27, 78, 0.4)',
                  borderRadius: '24px',
                  border: '1px solid rgba(43, 27, 78, 0.4)',
                  padding: below600 ? 0 : '24px',
                  height: '390px',
                }}
              >
                {/* {below1080 && price && (
                  <Panel>
                    <AutoColumn gap="20px">
                      <RowBetween>
                        <TYPE.main>Price</TYPE.main>
                        <div />
                      </RowBetween>
                      <RowBetween align="flex-end">
                        {' '}
                        <TYPE.main fontSize={'1.5rem'} lineHeight={1} fontWeight={500}>
                          {price}
                        </TYPE.main>
                        <TYPE.main>{priceChange}</TYPE.main>
                      </RowBetween>
                    </AutoColumn>
                  </Panel>
                )} */}
                <Panel style={{ border: 'none', background: 'none', padding: below600 ? '12px' : 0 }}>
                  <AutoColumn gap="8px">
                    <RowBetween>
                      <TextPurple>LIQUIDITY</TextPurple>
                    </RowBetween>
                    <RowBetween>
                      <TextCurrency>{formatCurrencyToString(totalLiquidityUSD ?? 0)}</TextCurrency>
                    </RowBetween>
                    <RowBetween>
                      <TYPE.main>{liquidityChange}</TYPE.main>
                    </RowBetween>
                  </AutoColumn>
                </Panel>
                <Panel style={{ border: 'none', background: 'none', padding: below600 ? '12px' : 0 }}>
                  <AutoColumn gap="8px">
                    <RowBetween>
                      <TextPurple>VOLUME</TextPurple>
                    </RowBetween>
                    <RowBetween>
                      <TextCurrency>{formatCurrencyToString(oneDayVolumeUSD)}</TextCurrency>
                    </RowBetween>
                    <RowBetween>
                      <TYPE.main>{volumeChange}</TYPE.main>
                    </RowBetween>
                  </AutoColumn>
                </Panel>
                <Panel style={{ border: 'none', background: 'none', padding: below600 ? '12px' : 0 }}>
                  <AutoColumn gap="8px">
                    <RowBetween>
                      <TextPurple>TRANSACTIONS 24H </TextPurple>
                      <div />
                    </RowBetween>
                    <RowBetween>
                      <TextCurrency>
                        {oneDayTxns ? formatNumberToString(oneDayTxns) : oneDayTxns === 0 ? 0 : '-'}
                      </TextCurrency>
                    </RowBetween>
                    <RowBetween>
                      <TYPE.main>{txnChangeFormatted}</TYPE.main>
                    </RowBetween>
                  </AutoColumn>
                </Panel>
              </PanelWrapper>

              <Panel
                style={{
                  marginTop: '1.5rem',
                  padding: '1.125rem',
                  width: below600 ? '100%' : '70%',
                  height: '440px',
                  background: 'rgba(43, 27, 78, 0.4)',
                  borderRadius: '24px',
                  border: '1px solid rgba(43, 27, 78, 0.4)',
                }}
              >
                <TokenChart address={address} color={backgroundColor} base={priceUSD} symbol={symbol} />
              </Panel>
            </FlexBox>

            <RowBetween style={{ marginTop: '3rem' }}>
              <TYPE.main fontSize={'1.125rem'}>Top Pairs</TYPE.main>
              {/* <AutoRow gap="4px" style={{ width: 'fit-content' }}>
                <Checkbox
                  checked={useTracked}
                  setChecked={() => setUseTracked(!useTracked)}
                  text={'Hide untracked pairs'}
                />
                <QuestionHelper text="USD amounts may be inaccurate in low liquiidty pairs or pairs without ETH or stablecoins." />
              </AutoRow> */}
            </RowBetween>
            <Panel
              rounded
              style={{
                marginTop: '1.5rem',
                padding: '1.125rem 0 ',
              }}
            >
              {address && fetchedPairsList ? (
                <PairList color={backgroundColor} address={address} pairs={fetchedPairsList} useTracked={true} />
              ) : (
                <Loader />
              )}
            </Panel>
            <RowBetween mt={40} mb={'1rem'}>
              <TYPE.main fontSize={'1.125rem'}>Transactions</TYPE.main> <div />
            </RowBetween>
            <RadioGroup />
            <Panel rounded>
              {transactions ? (
                <TxnList color={backgroundColor} transactions={transactions} txFilterTrans={txFilter} />
              ) : (
                <Loader />
              )}
            </Panel>
            {/* <>
              <RowBetween style={{ marginTop: '3rem' }}>
                <TYPE.main fontSize={'1.125rem'}>Token Information</TYPE.main>{' '}
              </RowBetween>
              <Panel
                rounded
                style={{
                  marginTop: '1.5rem',
                }}
                p={20}
              >
                <TokenDetailsLayout>
                  <Column>
                    <TYPE.main>Symbol</TYPE.main>
                    <Text style={{ marginTop: '.5rem' }} fontSize={24} fontWeight="500">
                      <FormattedName text={symbol} maxCharacters={12} />
                    </Text>
                  </Column>
                  <Column>
                    <TYPE.main>Name</TYPE.main>
                    <TYPE.main style={{ marginTop: '.5rem' }} fontSize={24} fontWeight="500">
                      <FormattedName text={name} maxCharacters={16} />
                    </TYPE.main>
                  </Column>
                  <Column>
                    <TYPE.main>Address</TYPE.main>
                    <AutoRow align="flex-end">
                      <TYPE.main style={{ marginTop: '.5rem' }} fontSize={24} fontWeight="500">
                        {address.slice(0, 8) + '...' + address.slice(36, 42)}
                      </TYPE.main>
                      <CopyHelper toCopy={address} />
                    </AutoRow>
                  </Column>
                  <ButtonLight color={backgroundColor}>
                    <Link color={backgroundColor} external href={'https://etherscan.io/address/' + address}>
                      View on Etherscan ↗
                    </Link>
                  </ButtonLight>
                </TokenDetailsLayout>
              </Panel>
            </> */}
          </DashboardWrapper>
        </WarningGrouping>
      </ContentWrapper>
    </PageWrapper>
  )
}

export default withRouter(TokenPage)
