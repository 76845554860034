import React from 'react'
import styled from 'styled-components'

const BoxIconSort = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 10px;
`

const IconSort = (dow) => {
  return (
    <>
      {dow ? (
        <BoxIconSort>
          <img src="/icons/up.svg" width="10px" alt="icon sort" height="10px" />
          <img src="/icons/down_blur.svg" width="10px" alt="icon sort" height="10px" style={{ opacity: '0.3' }} />
        </BoxIconSort>
      ) : (
        <BoxIconSort>
          <img src="/icons/up_blur.svg" alt="icon sort" width="10px" height="10px" style={{ opacity: '0.3' }} />
          <img src="/icons/down.svg" alt="icon sort" width="10px" height="10px" />
        </BoxIconSort>
      )}
    </>
  )
}

export default IconSort
